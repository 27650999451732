import Image from 'next/image'
import { Fragment } from 'react'
import Slider from 'react-slick'

import { benefitsData } from 'src/modules/landing/BenefitsSection'
import BenefitsCard, {
  IBenefitsData,
} from 'src/modules/landing/components/BenefitsCard'
import { MobileBenefitsSectionCont } from 'src/modules/landing/style'
import carousalArrow from 'public/assets/landing/carousalArrow.svg'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import {
  setShowConnectModal,
  setShowRegisterModal,
} from 'src/logic/redux/slices/uiSlice'
import { setRegisterProgress } from 'src/logic/redux/slices/authSlice'

interface ISampleArrowProps {
  className?: string
  onClick?: () => void
}

export const SampleArrow = (props: ISampleArrowProps) => {
  const { className, onClick } = props
  return (
    <Image
      src={carousalArrow}
      className={className}
      onClick={onClick}
      alt='arrow-icon'
    />
  )
}

const settings = {
  dots: true,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: 'linear',
  customPaging: () => <div className='tabs' />,
  nextArrow: <SampleArrow />,
  prevArrow: <SampleArrow />,
}

const MobileBenefitsSection = () => {
  const { token } = useAppSelector((state) => state.authReducer)
  const dispatch = useAppDispatch()
  return (
    <MobileBenefitsSectionCont>
      <Slider {...settings}>
        {benefitsData?.map((data: IBenefitsData, index: number) => (
          <Fragment key={index}>
            <BenefitsCard data={data} key={data.title} className='mobile' />
          </Fragment>
        ))}
      </Slider>

      <p>
        Note: To access the utility of the NFT you must have a digital wallet.
        If you need to create a digital wallet please start here or&nbsp;
        <span
          className='highlight'
          onClick={() => {
            if (token) {
              dispatch(setShowConnectModal(true))
            } else {
              dispatch(setShowRegisterModal(true))
              dispatch(setRegisterProgress(1))
            }
          }}
        >
          sign up
        </span>
        &nbsp;with just your email and we will create a wallet for you.
      </p>
    </MobileBenefitsSectionCont>
  )
}

export default MobileBenefitsSection
