import Image, { StaticImageData } from 'next/image'
import * as Styles from './style'
import { FlexCol } from 'styles/styled'

import { FadeIn } from 'shared/animations'
import SplitCard from 'shared/components/cards/SplitCard'

export interface IBenefitsData {
  title: string
  info: string | React.ReactNode
  img: string | StaticImageData
}

interface IBenefitsCardProps {
  data: IBenefitsData
  className?: string
}

const BenefitsCard = ({ data, className }: IBenefitsCardProps) => {
  const { img, info, title } = data
  return (
    <>
      {className === 'mobile' ? (
        <Styles.MobileBenefitsCont>
          <SplitCard>
            <Styles.BenefitsCardCont className={className}>
              <FlexCol gap='32px' alignItems='center'>
                <h1>{title}</h1>
                <p>{info}</p>
              </FlexCol>
            </Styles.BenefitsCardCont>
          </SplitCard>
          <Image src={img} alt='benefit-icon' className='icon' />
        </Styles.MobileBenefitsCont>
      ) : (
        <SplitCard>
          <Styles.BenefitsCardCont className={className}>
            <FlexCol gap='100px' alignItems='center'>
              <FadeIn>
                <div>
                  <Image src={img} alt='benefit-icon' fetchPriority='low' />
                </div>
              </FadeIn>
              <FlexCol gap='32px' alignItems='center'>
                <h1>{title}</h1>
                <p>{info}</p>
              </FlexCol>
            </FlexCol>
          </Styles.BenefitsCardCont>
        </SplitCard>
      )}
    </>
  )
}

export default BenefitsCard
