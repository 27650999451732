import Link from 'next/link'
import { Fragment } from 'react'

import { BenefitsSectionCont } from './style'
import BenefitsCard, { IBenefitsData } from './components/BenefitsCard'
import FireOne from 'public/assets/landing/fireOne.png'
import FireTwo from 'public/assets/landing/fireTwo.png'
import FireThree from 'public/assets/landing/fireThree.png'
import { FlexRow } from 'styles/styled'
import { FadeIn, FadeLeft, FadeRight } from 'shared/animations'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import {
  setShowConnectModal,
  setShowRegisterModal,
} from 'src/logic/redux/slices/uiSlice'
import { setRegisterProgress } from 'src/logic/redux/slices/authSlice'
import { VTRCONNECT_LINK } from 'shared/constants'

export const benefitsData: IBenefitsData[] = [
  {
    img: FireOne,
    info: (
      <>
        With ownership of the NFT in your digital wallet,&nbsp;
        <Link
          href={VTRCONNECT_LINK}
          className='highlight'
          target='_blank'
          rel='noopener noreferrer'
        >
          www.vtrconnect.com
        </Link>
        &nbsp;will recognize ownership and seamlessly bypass membership costs
        when your wallet is connected. If you do not have a digital wallet, no
        worries we will create one for you.
      </>
    ),
    title: 'Enjoy Free Access',
  },
  {
    img: FireTwo,
    info: (
      <>
        By connecting your digital wallet to&nbsp;
        <Link
          href={VTRCONNECT_LINK}
          className='highlight'
          target='_blank'
          rel='noopener noreferrer'
        >
          www.vtrconnect.com
        </Link>
        , and holding this NFT in your digital wallet, you will be eligible for
        Dogecoin rewards for various missions completed on the VTRConnect real
        estate platform.
      </>
    ),
    title: 'Access to Rewards',
  },
  {
    img: FireThree,
    info: (
      <>
        By owning this NFT at any given time in your digital wallet, you will be
        eligible for randomized passes to the leading retail real estate
        industry events where you can network and learn with other Retailers and
        Restaurant Operators, Landlords, and Industry Professionals.
      </>
    ),
    title: 'Enjoy event passes',
  },
]

const BenefitsSection = () => {
  const { token } = useAppSelector((state) => state.authReducer)
  const dispatch = useAppDispatch()

  return (
    <BenefitsSectionCont>
      <FlexRow flexWrap='wrap' gap='60px'>
        {benefitsData?.map((data: IBenefitsData, index: number) => (
          <Fragment key={data.title}>
            {index === 0 && (
              <FadeLeft>
                <BenefitsCard data={data} key={data.title} />
              </FadeLeft>
            )}
            {index === 1 && (
              <FadeIn>
                <BenefitsCard data={data} key={data.title} />
              </FadeIn>
            )}
            {index === 2 && (
              <FadeRight>
                <BenefitsCard data={data} key={data.title} />
              </FadeRight>
            )}
          </Fragment>
        ))}
      </FlexRow>

      <p>
        Note: To access the utility of the NFT you must have a digital wallet.
        If you need to create a digital wallet please start here or&nbsp;
        <span
          className='highlight'
          onClick={() => {
            if (token) {
              dispatch(setShowConnectModal(true))
            } else {
              dispatch(setShowRegisterModal(true))
              dispatch(setRegisterProgress(1))
            }
          }}
        >
          sign up
        </span>
        &nbsp;with just your email and we will create a wallet for you.
      </p>
    </BenefitsSectionCont>
  )
}

export default BenefitsSection
